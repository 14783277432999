import chroma from 'chroma-js';
import minBy from 'lodash/minBy';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import ckmeans from 'ckmeans';

import type { ColorShades, ColorShadesList, Shades } from '@blush/types';

export function isValidColor(color: string) {
  return chroma.valid(color);
}

/**
 * Returns true if the given color is darker than the threshold
 */
export function isDarkColor(color: string, threshold = 0.4) {
  try {
    if (getColorLuminosity(color) > threshold) {
      return false;
    }
  } catch (e) {}

  return true;
}

/**
 * Convert a color string to a hex
 */
export function colorToHex(color: string) {
  return chroma(color).hex();
}

/**
 * * Convert a hex string to a hsl array
 */
export function colorToHsl(color: string) {
  return chroma(color).hsl();
}

/**
 * Convert a hex string to an rgb array
 */
export function hexToRgb(hex: string) {
  return chroma(hex).rgb();
}

/**
 * Get the luminosity of a color
 */
export function getColorLuminosity(color: string) {
  return chroma(color).luminance();
}

/**
 * Set the luminosity of a color, returns a new color (hex)
 */
export function setColorLuminosity(color: string, value: number) {
  return chroma(color).luminance(value).hex();
}

/**
 * Get the alpha of a color
 */
export function getColorAlpha(color: string) {
  return chroma(color).alpha();
}

/**
 * Set the alpha of a color, returns a new color (rgba)
 */
export function setColorAlpha(color: string, value: number) {
  return chroma(color).alpha(value).hex();
}

/**
 * Get the difference (deltaE) between two colors
 */
export function getColorDifference(colorA: string, colorB: string) {
  return chroma.deltaE(colorA, colorB);
}

/**
 * Mixes two colors, returns a new color (hex)
 */
export function mixColors(colorA: string, colorB: string, ratio: number) {
  return chroma.mix(colorA, chroma(colorB), ratio).hex();
}

/**
 * Given a color, returns a lowercase hex code if valid
 */
export function sanitizeColor(color?: string): string | null {
  if (!color) return null;
  return chroma.valid(color) ? chroma(color).hex().toLowerCase() : null;
}

/**
 * Given a list of colors (any format) and get out a list of lowercase hex codes
 */
export function sanitizeColors(colors?: string[]): string[] {
  return colors ? (colors.map(sanitizeColor).filter(Boolean) as string[]) : [];
}

/**
 * Generates a list of shades from a colors list
 */
export function getColorsFromColorShadesList(colorShadesList: ColorShadesList): string[] {
  return colorShadesList.map((colors) => colors['500']);
}

/**
 * Generate a list of random colors (hex)
 */
export function getRandomColors(num: number): string[] {
  return range(num).map(() => chroma.random().hex());
}

export function makeColorShades(color: string): ColorShades | null {
  const sanitizedColor = sanitizeColor(color);

  if (!sanitizedColor) {
    return null;
  }

  const gradient = autoGradient(sanitizedColor, 9);
  const index = gradient.indexOf(sanitizedColor);

  const distanceFromMiddle = index - 4;

  if (distanceFromMiddle < 0) {
    gradient.unshift(...range(Math.abs(distanceFromMiddle)).map(() => gradient[0]!));
    gradient.splice(distanceFromMiddle);
  }
  if (distanceFromMiddle > 0) {
    gradient.push(...range(distanceFromMiddle).map(() => gradient[gradient.length - 1]!));
    gradient.splice(0, distanceFromMiddle);
  }

  const result: ColorShades = {
    500: color,
  };

  gradient.reverse();

  gradient.forEach((color, i) => {
    const shade = `${(i + 1) * 100}` as Shades;
    result[shade] = color;
  });

  return result;
}

/**
 * Removes the leading hashtag from a hex string
 */
export function ensureNoHexHashtag(hex = ''): string {
  return String(hex).replace('#', '');
}

export function closestColor(hex: string, colors: string[]): string {
  const result = minBy(colors, (color) => {
    try {
      return chroma.deltaE(hex, color);
    } catch (e) {
      return 1000;
    }
  });

  if (!result) {
    throw new Error(`Could not find color match for ${hex} in ${colors}`);
  }

  return result;
}

/**
 * Handles all of the post processing (applying skin colors, etc) to a generated SVG
 */
export function groupByHue(colors: string[], groups: number, threshold = 12): string[][] {
  if (groups === 1) {
    return [colors];
  }

  // This lists the hues in order between 0 and `threshold`
  // If threshold is 360 it lists them between 0 and 360, for example
  // If threshold is lower (like 12), it groups them all into 0 to 12 (so it fits close colors together)
  const ranksAndColors = sortBy(
    colors.map((x) => {
      const CHARTREUSE = 0;
      let hue = (chroma(x).hsl()[0] - CHARTREUSE) % 360;

      // NaN is greyscale
      // And we set it to a rank of double the threshold so it always goes into its own group
      const greyscaleRank = threshold * 2;

      return {
        hex: x,
        rank: isNaN(hue) ? greyscaleRank : Math.ceil((hue / 360) * threshold),
      };
    }),
    'hue',
  );

  const ranks = ranksAndColors.map((x) => x.rank);

  // TODO: Edit this function so it takes into account that hue 0 is close to hue 360, not far from it
  // use the circularDistance() method
  const means: number[] = ckmeans(ranks, groups);

  const result = means.map((min, i) => {
    const nextIndex = i + 1;
    const max = means[nextIndex];

    const segment = ranks.filter((x) => x >= min && (typeof max === 'undefined' || x < max));

    return segment.map((rank) => {
      const matchIndex = ranksAndColors.findIndex((x) => x.rank === rank);
      if (matchIndex !== -1) {
        const result = ranksAndColors[matchIndex]!.hex;
        ranksAndColors[matchIndex]!.rank = -1; // Discard
        return result;
      }
      return '';
    });
  });

  return result;
}

export function autoGradient(color: string, numColors: number): string[] {
  const lab = chroma(color).lab();
  const lRange = 100 * (0.95 - 1 / numColors);
  const lStep = lRange / (numColors - 1);
  let lStart = (100 - lRange) * 0.5;
  const arrRange = range(lStart, lStart + numColors * lStep, lStep);
  let offset = 9999;
  for (let i = 0; i < numColors; i++) {
    let diff = lab[0] - arrRange[i]!;
    if (Math.abs(diff) < Math.abs(offset)) {
      offset = diff;
    }
  }
  return arrRange.map((l) => chroma.lab(l + offset, lab[1], lab[2]).hex());
}

export function createColorScale(colors: string[], newColor: string) {
  const sortedColors = colors.map((hex) => ({
    hex,
    luminosity: getColorLuminosity(hex),
  }));

  // Sort dark -> light
  sortedColors.sort((a, b) => a.luminosity - b.luminosity);

  const darkest = sortedColors[0]!;
  const lightest = sortedColors[sortedColors.length - 1]!;

  const lightestColor = setColorLuminosity(newColor, lightest.luminosity);
  const darkestColor = setColorLuminosity(newColor, darkest.luminosity);

  const scale = chroma
    .scale([lightestColor, newColor, darkestColor])
    .classes(sortedColors.map((x) => x.luminosity));

  return sortedColors.map(({ hex, luminosity }) => {
    const newHex = scale(luminosity).hex();
    return { oldHex: hex, newHex };
  });
}

export function createGradientScale(colors: string[], newColors: string[]) {
  // Sort old colors dark -> light
  const sortedColors = colors.map((hex) => ({
    hex,
    luminosity: getColorLuminosity(hex),
  }));

  sortedColors.sort((a, b) => a.luminosity - b.luminosity);

  const lightest = sortedColors[sortedColors.length - 1]!;
  const darkest = sortedColors[0]!;

  // Sort new colors dark -> light
  const sortedNewColors = newColors.map((hex) => ({
    hex,
    luminosity: getColorLuminosity(hex),
  }));

  sortedNewColors.sort((a, b) => a.luminosity - b.luminosity);

  const scale = chroma
    .scale(sortedNewColors.map(({ hex }) => hex))
    .mode('lab')
    .domain([darkest.luminosity, lightest.luminosity])
    .correctLightness()
    .classes(sortedColors.map((x) => x.luminosity));

  return sortedColors.map(({ hex, luminosity }) => {
    const newHex = scale(luminosity).hex();
    return { oldHex: hex, newHex };
  });
}
