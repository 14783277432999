import React, { useRef } from 'react';
import classnames from 'classnames';

import { Button, Text, Input, Select } from '@blush/mascara';
import {
  ASPECT_RATIOS,
  ASPECT_RATIOS_OPTIONS,
  DEFAULT_HEIGHT,
  DEFAULT_WIDTH,
} from 'common/constants';
import { useIllustration } from 'context/Illustration';
import { round } from '@blush/utils';
import { useAnalytics } from 'hooks/useAnalytics';

import type { DoodleIpsum } from '@blush/types';

function getSizeFromAspectRatio(
  ratio: number | null,
  width: number | undefined,
  height: number | undefined,
) {
  if (ratio === null) {
    if (width) {
      return { height: undefined };
    }

    return { width: undefined };
  }

  if (width) {
    return { height: width / ratio };
  }

  if (height) {
    return {
      width: height ?? DEFAULT_HEIGHT * ratio,
    };
  }

  return {
    width: DEFAULT_WIDTH,
    height: DEFAULT_WIDTH / ratio,
  };
}

export const ProportionsInput: React.FC = () => {
  const { metadata, update, params } = useIllustration();
  const analytics = useAnalytics();
  const [isCustom, setIsCustom] = React.useState(false);
  const widthInput = useRef<HTMLInputElement>(null);
  const heightInput = useRef<HTMLInputElement>(null);
  const { width, height } = params;

  const isAutoSize = !width || !height;
  const currentAspectRatio =
    (width || DEFAULT_WIDTH) / (height || DEFAULT_HEIGHT);

  function setSize(size: DoodleIpsum.ImageSize) {
    const newWidth = 'width' in size ? size.width : width;
    const newHeight = 'height' in size ? size.height : height;

    update({
      width: newWidth ? Math.round(newWidth) : undefined,
      height: newHeight ? Math.round(newHeight) : undefined,
      id: metadata?.id,
    });

    analytics.track('Change size', { size });
  }

  function handleChangeWidthInput() {
    const input = widthInput.current?.value.replace(/[^\d.]/gi, '');
    setSize({
      width: input ? Math.round(Number(input.slice(0, 4))) : undefined,
    });
  }

  function handleChangeHeightInput() {
    const input = heightInput.current?.value.replace(/[^\d.]/gi, '');
    setSize({
      height: input ? Math.round(Number(input.slice(0, 4))) : undefined,
    });
  }

  return (
    <div className="flex flex-col xl:flex-row sm:mr-auto">
      <div className="flex sm:hidden flex-grow">
        <Select
          name="style"
          options={ASPECT_RATIOS_OPTIONS}
          size="lg"
          className="w-full"
          onChange={(e) => {
            const { value } = e.target;
            if (value === 'custom') {
              setIsCustom(true);
              return;
            }

            setIsCustom(false);

            const isRatio = value.includes(',');

            if (isRatio) {
              const [x, y] = value.split(',').map((v) => parseInt(v, 10)) as [
                number,
                number,
              ];
              const ratioFraction = x / y;
              setSize(getSizeFromAspectRatio(ratioFraction, width, height));
            }
          }}
        />
      </div>
      <div className="hidden sm:flex gap-2 justify-start">
        {ASPECT_RATIOS.map((ratio, i) => {
          const ratioFraction =
            ratio.value && ratio.value[0]! / ratio.value[1]!;
          const isActive = isAutoSize
            ? ratioFraction === null
            : ratioFraction
            ? round(currentAspectRatio) === round(ratioFraction)
            : false;

          return (
            <Button
              key={i}
              size="md"
              variant={isActive ? 'active' : 'secondary'}
              onClick={() => {
                setSize(getSizeFromAspectRatio(ratioFraction, width, height));
              }}
            >
              {ratio.label}
            </Button>
          );
        })}
      </div>
      <div
        className={classnames(
          'sm:flex flex-shrink gap-4 border-ink-100 border-t mt-2 pt-2 xl:mt-0 xl:pt-0 xl:border-t-0 xl:border-l xl:pl-2 xl:ml-2',
          isCustom ? 'flex' : 'hidden',
        )}
      >
        <label className="flex items-center flex-grow gap-2">
          <Text as="span" variant="body-strong">
            w
          </Text>
          <Input
            ref={widthInput}
            size="md"
            selectOnFocus
            name="width"
            type="number"
            className="w-14 flex-grow"
            defaultValue={width || ''}
            key={width || ''}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleChangeWidthInput();
              }
            }}
            onBlur={() => {
              handleChangeWidthInput();
            }}
          />
        </label>
        <label className="flex items-center flex-grow gap-2">
          <Text as="span" variant="body-strong">
            h
          </Text>
          <Input
            ref={heightInput}
            size="md"
            selectOnFocus
            name="height"
            type="number"
            className="w-14 flex-grow"
            defaultValue={height || ''}
            key={height || ''}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleChangeHeightInput();
              }
            }}
            onBlur={() => {
              handleChangeHeightInput();
            }}
          />
        </label>
      </div>
    </div>
  );
};
