import React from 'react';
import classnames from 'classnames';

import { Field } from '../Field';
import { Label } from '../Label';
import { Spacing } from '../../Spacing';
import { Text } from '../../Text';

import type { BaseInputProps } from '../base';

import styles from './styles.module.css';

interface ToggleProps extends BaseInputProps<boolean, HTMLInputElement> {
  defaultChecked?: boolean;
  checked?: boolean;
  labelPosition?: 'left' | 'right';
  size?: 'sm' | 'md';
  ref?: React.Ref<HTMLInputElement>;
}

export const Toggle: React.FC<ToggleProps> = React.forwardRef<HTMLInputElement, ToggleProps>(
  (
    {
      className,
      description,
      defaultChecked,
      disabled,
      label,
      labelPosition = 'left',
      labelVariant,
      name,
      onBlur,
      onChange,
      onFocus,
      size = 'md',
      checked,
      id = name,
    },
    ref,
  ) => {
    const inputRef = React.useRef<HTMLInputElement>();

    const inputRefCallback = React.useCallback(
      (element) => {
        if (typeof ref === 'function') {
          ref(element);
        }

        if (ref && 'current' in ref) {
          ref.current = element;
        }

        inputRef.current = element;
      },
      [ref],
    );

    const handleClick = React.useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
    }, []);

    React.useEffect(() => {
      if (!inputRef.current || typeof defaultChecked === 'undefined') {
        return;
      }

      inputRef.current.checked = defaultChecked;
    }, [defaultChecked]);

    return (
      <Field className={className}>
        <div
          className={classnames('flex space-x-2 items-center', {
            'space-x-reverse flex-row-reverse': labelPosition === 'right',
          })}
        >
          {(label || description) && (
            <Spacing stack gap={2}>
              {label && (
                <Label htmlFor={id} variant={labelVariant}>
                  {label}
                </Label>
              )}
              {description && (
                <Text variant="caption" color="ink-500">
                  {description}
                </Text>
              )}
            </Spacing>
          )}
          <label
            className={classnames(
              'flex',
              disabled ? 'cursor-default opacity-60 pointer-events-none' : 'cursor-pointer',
            )}
            onClick={handleClick}
          >
            <input
              type="checkbox"
              defaultChecked={defaultChecked}
              disabled={disabled}
              name={name}
              id={id}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChange}
              checked={checked}
              ref={inputRefCallback}
              className={classnames(styles.input, 'input-reset opacity-0 w-0 h-0')}
            />
            <span className={classnames(styles.dot, styles[size])} />
          </label>
        </div>
      </Field>
    );
  },
);

Toggle.displayName = 'Toggle';
