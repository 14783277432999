import { Spacing, Text } from '@blush/mascara';
import React from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
}

export const DocsSectionHeader = ({ title, children }: Props) => {
  return (
    <Spacing stack gap={6} className="text-center mb-8">
      <Text as="h1" variant="hero" className="hidden xl:block">
        {title}
      </Text>
      <Text as="h1" variant="display-xl" className="block xl:hidden">
        {title}
      </Text>
      {children}
    </Spacing>
  );
};
