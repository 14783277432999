import useDarkModeImpl, { DarkMode } from 'use-dark-mode';

import { THEME_CLASS_NAMES } from '../constants';

const defaultOptions = {
  classNameDark: THEME_CLASS_NAMES.DARK,
  classNameLight: THEME_CLASS_NAMES.LIGHT,
};

export type UseDarkModeReturn = DarkMode;

export function useDarkMode(initialState = false) {
  return useDarkModeImpl(initialState, defaultOptions);
}
