import React from 'react';

import { IconButton, Spacing, Text, Tooltip } from '@blush/mascara';
import { getImageUrl } from 'utils/api';
import { useAnalytics } from 'hooks/useAnalytics';
import Image from 'next/image';

export const AvatarsCard: React.FC = () => {
  const [i, random] = React.useReducer((i) => i + 8, 0);
  const analytics = useAnalytics();

  function handleRandom() {
    random();
    analytics.track('Shuffle', { card: 'Avatars' });
  }

  return (
    <div className="bg-ink-000 dark:bg-ink-050 border border-transparent dark:border-ink-100 p-3 sm:p-6 rounded-2xl w-full doodle-card">
      <Spacing stack gap={4} className="h-full">
        <div className="group flex flex-grow flex-col w-full relative">
          <div className="grid grid-cols-4 gap-2">
            {Array.from({ length: 8 }, (_, index) => (
              <div
                style={{ height: 142 }}
                className="w-full relative"
                key={`${i}.${index}`}
              >
                <Image
                  layout="fill"
                  objectFit="contain"
                  loader={({ src }) => src}
                  src={getImageUrl({
                    style: 'avatar',
                    width: 300,
                    query: { pad: 30, n: i + index + 1 },
                  })}
                  className="bg-ink-050 dark:bg-ink-200 rounded-xl "
                  alt={`Random illustration ${index + 1}`}
                />
              </div>
            ))}
          </div>
          <div className="absolute top-2 right-2 md:opacity-0 group-hover:opacity-100">
            <Tooltip content="Shuffle">
              <IconButton icon="random" onClick={handleRandom} />
            </Tooltip>
          </div>
        </div>
        <Text as="h3" variant="display-md">
          Multiple random images
        </Text>
        <Text as="div" className="flex-grow">
          If you are placing multiple random images on a page, you may notice
          they appear all as the same image due to browser caching.
          <br />
          <br />
          To ensure they are all unique, append a cache-bust parameter (such as{' '}
          <strong>n=1</strong>, <strong>n=2</strong>, <strong>n=3</strong>
          , etc) to the end of the URL.
          <br />
          <br />
          Example:
          <br />
          <pre className="bg-ink-050 py-3 px-4 rounded-xl mt-1 overflow-y-auto">
            <code className="">
              {`<div style="display: flex; gap: 8px">
  <img src="https://doodleipsum.com/100x100/avatar?bg=lightgray&n=1" />
  <img src="https://doodleipsum.com/100x100/avatar?bg=lightgray&n=2" />
  <img src="https://doodleipsum.com/100x100/avatar?bg=lightgray&n=3" />
  <img src="https://doodleipsum.com/100x100/avatar?bg=lightgray&n=4" />
</div>`}
            </code>
          </pre>
        </Text>
      </Spacing>
    </div>
  );
};
