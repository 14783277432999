import React from 'react';
import classnames from 'classnames';

import { ButtonIncognito } from '../../ButtonIncognito';
import { Icon, IconType } from '../../Icons';
import { ErrorMessage } from '../ErrorMessage';
import { Field } from '../Field';
import { Label } from '../Label';
import { Text } from '../../Text';

import type { BaseInputProps, InputSize, InputVariant } from '../base';

import styles from '../styles.module.css';

const noop = () => {};

export interface InputProps extends BaseInputProps<string | number, HTMLInputElement> {
  // The clear button can be either controlled (boolean) or automatic ('auto') which
  // means it shows based on the value of the input.
  clearable?: boolean | 'auto';
  iconLeft?: IconType | React.ReactElement;
  max?: number | string;
  min?: number | string;
  onClear?: () => void;
  readOnly?: boolean;
  selectOnFocus?: boolean;
  size?: InputSize;
  style?: React.CSSProperties;
  type?: 'text' | 'number' | 'email' | 'tel' | 'password' | 'hidden' | 'search' | 'url';
  value?: string | number;
  variant?: InputVariant;
  step?: number | 'any';
  maxLength?: number;
  minLength?: number;
  required?: boolean;
  tabIndex?: number;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      clearable,
      description,
      iconLeft,
      label,
      labelVariant,
      onClear,
      name,
      type = 'text',
      variant = 'outlined',
      size = 'md',
      readOnly = false,
      id = name,
      error,
      maxLength,
      minLength,
      required,
      selectOnFocus,
      tabIndex,
      ...props
    },
    ref,
  ) => {
    const inputRef = React.useRef<HTMLInputElement>();
    const [hasValue, setHasValue] = React.useState(!!(props.value || props.defaultValue));

    const inputRefCallback = React.useCallback(
      (element) => {
        if (typeof ref === 'function') {
          ref(element);
        }

        if (ref && 'current' in ref) {
          ref.current = element;
        }

        inputRef.current = element;
      },
      [ref],
    );

    function handleClear() {
      if (!inputRef.current) {
        return;
      }

      // We do not dispatch a change event for clear
      inputRef.current.value = '';
      setHasValue(false);
      onClear?.();
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (clearable === 'auto') {
        const value = event.target.value;
        setHasValue(!!value);
      }

      props.onChange?.(event);
    }

    function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
      const inputEl = event.target;

      if (inputEl && selectOnFocus) {
        inputEl.select();
      }

      props.onFocus?.(event);
    }

    if (type === 'hidden') {
      return <input {...props} type={type} ref={ref} />;
    }

    const inputProps: React.HTMLProps<HTMLInputElement> = {
      ...props,
      onChange: handleChange,
      onFocus: handleFocus,
      id,
      name,
      className: classnames(
        styles.input,
        styles[`size-${size}`],
        styles[`padding-${size}`],
        styles[`variant-${variant}`],
        {
          [styles[`size-${size}--icon-left`] as string]: iconLeft,
          [styles[`size-${size}--icon-right`] as string]: clearable,
        },
        error && styles.error,
      ),
      type,
      maxLength,
      minLength,
      required,
      readOnly,
      tabIndex,
      ref: inputRefCallback,
    };

    const showClearButton = clearable === 'auto' ? hasValue : clearable;

    if (readOnly) {
      delete inputProps.defaultValue;
      inputProps.value = props.value || props.defaultValue;
      inputProps.onChange = noop;
      inputProps.onBlur = noop;
    }

    return (
      <Field className={className}>
        {label && (
          <Label htmlFor={id} variant={labelVariant} disabled={inputProps.disabled}>
            {label}
          </Label>
        )}
        {description && (
          <Text variant="caption" color="ink-500">
            {description}
          </Text>
        )}
        <div className="relative">
          {iconLeft && (
            <div
              className={classnames(
                'absolute left-0 inset-y-0 flex items-center',
                styles[`padding-${size}`],
              )}
            >
              {typeof iconLeft === 'string' ? (
                <Icon type={iconLeft} size="1rem" color="ink-500" />
              ) : (
                iconLeft
              )}
            </div>
          )}
          <input {...inputProps} />
          {showClearButton && (
            <ButtonIncognito
              as="button"
              type="button"
              onClick={handleClear}
              className={classnames(
                'button-reset absolute right-0 inset-y-0 flex items-center',
                styles[`padding-${size}`],
              )}
            >
              <Icon type="close" size="1rem" color="ink-500" />
            </ButtonIncognito>
          )}
        </div>
        {error && <ErrorMessage error={error} />}
      </Field>
    );
  },
);

Input.displayName = 'Input';
