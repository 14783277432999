import React from 'react';

import { Button, HorizontalScroll, Spacing } from '@blush/mascara';
import { Card } from 'components/Card';
import { ART_STYLES } from '@blush/constants';
import { getImageUrl } from 'utils/api';
import { CopyInput } from 'components/CopyInput';

export const CategoryCard: React.FC = () => {
  const [category, setCategory] = React.useState('flat');

  return (
    <Card
      title="Use a Specific Category"
      params={{
        style: category,
        width: 600,
      }}
      imgClassName="bg-ink-050 dark:bg-ink-200"
    >
      Use a specific category by appending <strong>/[category]</strong> to the
      url.
      <HorizontalScroll className="mt-4 mb-4">
        <Spacing gap={2}>
          {ART_STYLES.map((artStyle) => (
            <Button
              key={artStyle.value}
              size="md"
              variant={category === artStyle.value ? 'active' : 'secondary'}
              onClick={() => {
                setCategory(artStyle.value);
              }}
            >
              <img
                alt={artStyle.label}
                src={getImageUrl({
                  style: artStyle.value,
                  height: 100,
                })}
                className="mr-3 h-6 -ml-0.5"
              />
              {artStyle.label}
            </Button>
          ))}
        </Spacing>
      </HorizontalScroll>
      <CopyInput
        value={`<img src="https://doodleipsum.com/500/${category}" />`}
        style={{ maxWidth: 500 }}
      />
    </Card>
  );
};
