import React from 'react';
import classnames from 'classnames';

import { Box } from '../Box';
import { Icon, IconType } from '../Icons';
import type { ButtonVariant, ButtonSize } from '../Button';

// There are some cases where an icon button accepts children, like text. To keep
// everything typesafe, we accept a null icon which will show the children instead
type IconTypeNone = null;

export type IconButtonProps = React.PropsWithChildren<{
  as?: string;
  className?: string;
  color?: string;
  disabled?: boolean;
  href?: string;
  icon: IconType | IconTypeNone;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
  rel?: string;
  target?: string;
  size?: ButtonSize;
  type?: 'button' | 'reset' | 'submit';
  variant?: ButtonVariant;
}>;

const variants: Record<ButtonVariant, string> = {
  active: 'btn--active',
  primary: 'btn--primary',
  secondary: 'btn--secondary',
  danger: 'btn--danger',
  naked: 'btn--naked',
  link: 'btn--link',
};

const sizes: Record<ButtonSize, string> = {
  inherit: 'icon-btn--inherit',
  sm: 'icon-btn--sm',
  md: 'icon-btn--md',
  lg: 'icon-btn--lg',
  xl: 'icon-btn--xl',
};

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      as = 'button',
      children,
      className,
      color,
      variant = 'secondary',
      disabled = false,
      icon,
      label,
      size = 'md',
      type = 'button',
      ...props
    },
    ref,
  ) => {
    return (
      <Box
        as={as}
        aria-label={label}
        disabled={disabled}
        ref={ref}
        className={classnames(
          className,
          'icon-btn',
          variants[variant],
          sizes[size],
        )}
        type={as !== 'a' ? type : undefined}
        {...props}
      >
        {icon ? (
          <Icon
            type={icon}
            color={color}
            className="icon-btn--icon max-w-full max-h-full"
            size=""
          />
        ) : (
          children
        )}
      </Box>
    );
  },
);

IconButton.displayName = 'IconButton';
