import type { NextPage } from 'next';

import { Spacing, Text } from '@blush/mascara';
import { AvatarsCard } from 'components/AvatarsCard';
import { CategoryCard } from 'components/CategoryCard';
import { Container } from 'components/Container';
import { Card } from 'components/Card';
import { Hero } from 'components/Hero';
import { Footer } from 'components/Footer';
import { MetaData } from 'components/MetaData';
import { APIBanner } from 'components/APIBanner';
import { getImageUrl } from 'utils/api';
import { IS_PROD } from 'common/constants';
import { CopyInput } from 'components/CopyInput';
import { DocsSectionHeader } from 'components/DocsSectionHeader';

const staticImageIds = IS_PROD
  ? [
      '23243fc71ac1a810a5873bde01e17f07',
      'eb82b7ba75b3a5086fff159602ef32f2',
      '6acb18e63345c514f85f42d725ea9e27',
      'c6c365b3485912d7b1e102812d97162c',
      '23243fc71ac1a810a5873bde01e17f07',
    ]
  : [
      'aceMtaHk6aRzyy9weA1Z',
      '2ce17ffbc512fd10ae9ac90695484ba1',
      '1b043f7cbb37053ec7c6ca00a7f6f4e7',
      'HY34VTqzfBBr0lwStimY',
      'c5e92a6cda3995ea6447395d07984861',
    ];

const Home: NextPage = () => {
  return (
    <>
      <MetaData />
      <main className="flex flex-col bg-ink-000">
        <Hero />
        <div className="flex flex-col items-center bg-teal-light dark:bg-ink-000">
          <Container className="flex-col py-14 md:py-40 space-y-6 md:space-y-8">
            <DocsSectionHeader title="How to use Doodle Ipsum">
              <Spacing stack gap={1}>
                <Text variant="body-lg">
                  Embed a random image in your project with the following URL
                  format:
                </Text>
                <Text variant="body-lg" className="break-words">
                  https://doodleipsum.com/<strong>[width]</strong>x
                  <strong>[height]</strong>/<strong>[category]</strong>
                </Text>
              </Spacing>

              <Spacing stack gap={2}>
                <Text variant="body-lg">Example:</Text>
                <Text as="div" variant="body-lg">
                  <CopyInput
                    value={`<img src="https://doodleipsum.com/500x500/flat" />`}
                    className="max-w-prose mx-auto"
                  />
                </Text>
              </Spacing>
            </DocsSectionHeader>

            <div className="flex w-full flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
              <Card
                title="Dimensions"
                params={{
                  style: 'flat',
                  width: 800,
                  height: 600,
                }}
                initialImageId={staticImageIds[3]}
                imgClassName="bg-ink-050 dark:bg-ink-200"
              >
                Specify dimensions by using <strong>[width]x[height]</strong> in
                the URL.
                <br />
                <br />
                Only one dimension is required, for example to get an image
                500px tall use <strong>https://doodleipsum.com/x500</strong>
              </Card>
              <Card
                title="Static Image"
                params={{
                  style: 'flat',
                  width: 600,
                  id: staticImageIds[4],
                }}
                imgClassName="bg-ink-050 dark:bg-ink-200"
              >
                By default you will get a random image on every page load.
                <br />
                <br />
                To get a static image, visit the link in your browser. It will
                redirect you to a static URL that you can use.
                <br />
                <br />
                <strong>Tip:</strong> It forwards you to a URL with{' '}
                <strong>i=[imageId]</strong> - you can remove this to randomize
                the image again, or use the tool at the top of this page to
                generate the static link.
              </Card>
            </div>

            <CategoryCard />
            <div className="flex w-full flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
              <AvatarsCard />
            </div>

            <DocsSectionHeader title="Advanced Features">
              <Spacing stack gap={4}>
                <Text variant="body-lg" className="mx-auto">
                  Use Doodle Ipsum&apos;s advanced features by adding special
                  query parameters.
                </Text>
                <Text variant="body-lg" className="mx-auto">
                  To add parameters, a <strong>?</strong> is added after the
                  url, and an <strong>&</strong> is added in between each
                  parameter.
                </Text>
              </Spacing>

              <Spacing stack gap={2}>
                <Text variant="body-lg">Example:</Text>
                <Text as="div" variant="body-lg">
                  <CopyInput
                    value={`https://doodleipsum.com/600/flat?shape=circle&bg=ceebff`}
                    className="max-w-prose mx-auto"
                  />
                </Text>
              </Spacing>
            </DocsSectionHeader>
            <div className="flex w-full flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
              <Card
                title="Background Color"
                params={{
                  style: 'flat',
                  width: 800,
                  height: 600,
                  background: '#ceebff',
                }}
                initialImageId={staticImageIds[0]}
                copyUrl={getImageUrl({
                  width: 600,
                  background: '#ceebff',
                })}
                backgroundColor="#ceebff"
              >
                Add a background color with the <strong>bg=[hex]</strong> query
                parameter.
              </Card>
              <Card
                title="Circle"
                params={{
                  style: 'avatar',
                  width: 600,
                  background: '#ceebff',
                  query: {
                    shape: 'circle',
                  },
                }}
                copyUrl={getImageUrl({
                  width: 600,
                  background: '#ceebff',
                  query: {
                    shape: 'circle',
                  },
                })}
              >
                Get a circle with the <strong>shape=circle</strong> query
                parameter.
                <br />
                <br />
                <strong>Tip:</strong> Also add a background color (otherwise the
                circle is invisible).
              </Card>
            </div>

            <div className="flex w-full flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
              <Card
                title="Grayscale"
                params={{
                  style: 'flat',
                  width: 800,
                  height: 600,
                  query: { sat: '-100' },
                }}
                initialImageId={staticImageIds[2]}
                copyUrl={getImageUrl({
                  width: 600,
                  query: { sat: '-100' },
                })}
                imgClassName="bg-ink-050 dark:bg-ink-200"
              >
                Get a grayscale image with the <strong>sat=-100</strong> query
                parameter.
              </Card>
              <Card
                title="Blur"
                params={{
                  style: 'flat',
                  width: 800,
                  height: 600,
                  query: { blur: '50' },
                }}
                initialImageId={staticImageIds[3]}
                copyUrl={getImageUrl({
                  width: 600,
                  query: { blur: '50' },
                })}
                imgClassName="bg-ink-050 dark:bg-ink-200"
              >
                Get a blurred image with the <strong>blur=[amount]</strong>{' '}
                query parameter.
              </Card>
            </div>

            <APIBanner />
          </Container>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Home;
