import React from 'react';
import classnames from 'classnames';
import Link from 'next/link';

import { Box } from '../Box';
import { LoadingSpinner } from '../LoadingSpinner';
import { Icon, IconType } from '../Icons';

// Button variants and sizes are just classes generated by a tailwind plugin because they
// need responsive and/or support dark mode.
export type ButtonVariant = 'active' | 'primary' | 'secondary' | 'danger' | 'naked' | 'link';

export type ButtonSize = 'inherit' | 'sm' | 'md' | 'lg' | 'xl';

export type ButtonProps = React.PropsWithChildren<{
  as?: string;
  className?: string;
  disabled?: boolean;
  download?: string;
  href?: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
  rel?: string;
  size?: ButtonSize;
  scroll?: boolean;
  style?: React.CSSProperties;
  target?: string;
  type?: 'button' | 'reset' | 'submit';
  variant?: ButtonVariant;
}>;

const variants: Record<ButtonVariant, string> = {
  active: 'btn--active',
  primary: 'btn--primary',
  secondary: 'btn--secondary',
  danger: 'btn--danger',
  naked: 'btn--naked',
  link: 'btn--link',
};

const sizes: Record<ButtonSize, string> = {
  inherit: 'btn--inherit',
  sm: 'btn--sm',
  md: 'btn--md',
  lg: 'btn--lg',
  xl: 'btn--xl',
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as = 'button',
      className,
      variant = 'primary',
      disabled = false,
      loading = false,
      href,
      iconLeft,
      iconRight,
      children,
      size = 'md',
      target,
      type = 'button',
      scroll,
      ...props
    },
    ref,
  ) => {
    const isExternalLink = href && href.startsWith('http');

    const button = (
      <Box
        as={href ? 'a' : as}
        href={isExternalLink ? href : undefined}
        target={target}
        type={href ? undefined : type}
        disabled={disabled}
        ref={ref}
        className={classnames(className, 'btn', variants[variant], sizes[size])}
        {...props}
      >
        {loading && <LoadingSpinner isCentered color="current" className="transform scale-75" />}
        <span
          className={classnames(
            'flex items-center justify-center h-full w-full',
            loading ? 'opacity-0' : 'opacity-100',
          )}
        >
          {iconLeft && <Icon type={iconLeft} className="btn--icon-left" size="" />}
          {children}
          {iconRight && <Icon type={iconRight} className="btn--icon-right" size="" />}
        </span>
      </Box>
    );

    if (href && !isExternalLink) {
      return (
        <Link href={href} passHref scroll={scroll}>
          {button}
        </Link>
      );
    }

    return button;
  },
);

Button.displayName = 'Button';
