import React from 'react';
import Head from 'next/head';

const title = 'Doodle Ipsum';
const description =
  'Illustration placeholders for developers. Powered by Blush';
const image =
  'https://doodleipsum.com/1000x750/flat?bg=e3f5f8&i=23243fc71ac1a810a5873bde01e17f07';
const twitterUser = 'blushdesignapp';

export const MetaData: React.FC = () => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="icon" href="/favicon.ico" />
    <meta
      name="viewport"
      content="width=device-width, maximum-scale=1, initial-scale=1.0"
    />
    <meta key="description" name="description" content={description} />
    <meta
      key="twitter:card"
      name="twitter:card"
      content="summary_large_image"
    />
    <meta key="twitter:site" name="twitter:site" content={twitterUser} />
    <meta key="twitter:creator" name="twitter:creator" content={twitterUser} />
    <meta key="og:title" property="og:title" content={title} />
    <meta
      key="og:description"
      property="og:description"
      content={description}
    />
    <meta key="og:image" property="og:image" content={image} />
    <meta key="og:url" property="og:url" content="https://doodleipsum.com" />
    <meta key="og:type" property="og:type" content="website" />
  </Head>
);
