import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.css';

interface ContainerProps {
  className?: string;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
}) => <div className={classnames(styles.container, className)}>{children}</div>;
