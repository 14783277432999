import React from 'react';
import classnames from 'classnames';

import { Box } from '../Box';

import * as icons from './svgs';

export type IconType = keyof typeof icons;

export interface IconProps {
  className?: string;
  size?: string;
  type: IconType;
  color?: string;
  label?: string;
}

function isRawColor(color: string) {
  return color && (color.startsWith('#') || color.startsWith('rgb'));
}

// Render all of the icon components and save them for use later, like a constant
const SVGComponents = Object.entries(icons).reduce((prev, [type, Svg]) => {
  prev[type as IconType] = (
    <Svg className="block overflow-hidden pointer-events-none w-full h-full" />
  );
  return prev;
}, {} as Record<IconType, React.ReactNode>);

export const Icon = React.forwardRef<HTMLDivElement, IconProps>(
  ({ color = 'current', className, type, size = '24px', ...props }, ref) => {
    const SVGComponent = SVGComponents[type];

    if (!SVGComponent) {
      return null;
    }

    const style: React.CSSProperties = {};

    if (isRawColor(color)) {
      style.color = color;
    }

    if (size) {
      style.width = size;
      style.height = size;
    }

    return (
      <Box
        {...props}
        ref={ref}
        className={classnames(
          className,
          'inline-flex flex-shrink-0',
          `text-${color}`,
        )}
        style={style}
      >
        {SVGComponent}
      </Box>
    );
  },
);

Icon.displayName = 'Icon';
