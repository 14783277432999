import React from 'react';

import { Text } from '../../Text';

import type { FieldError } from '../base';

interface ErrorMessageProps {
  error: FieldError;
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const message = error.message;

  if (!message) {
    return null;
  }

  return (
    <Text as="div" variant="caption" color="danger">
      {message}
    </Text>
  );
};
