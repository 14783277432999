import React from 'react';
import classnames from 'classnames';

import { Button, Spacing, Text } from '@blush/mascara';
import { API_WAITLIST_URL } from 'common/constants';
import { useAnalytics } from 'hooks/useAnalytics';

import styles from './styles.module.css';

export const APIBanner: React.FC = () => {
  const analytics = useAnalytics();

  return (
    <div
      className={classnames(
        'flex flex-col-reverse md:flex-row p-6 md:px-16 md:py-12 rounded-3xl items-center',
        styles.bg,
      )}
    >
      <Spacing stack gap={4} className="items-start">
        <Text as="h3" variant="display-lg" color="white">
          Use the Blush API on your own project
        </Text>
        <Text as="strong" variant="body" color="white">
          Doodle Ipsum is just an example of what you can do with the Blush API.
          Do you have a creative tool that also needs doodles? Get on the
          waitlist for when it’s launched.
        </Text>
        <Button
          variant="active"
          size="xl"
          href={API_WAITLIST_URL}
          target="_blank"
          rel="noopener"
          onClick={() => analytics.track('Click API waitlist')}
          className="w-full sm:w-auto"
        >
          Get on the Waitlist
        </Button>
      </Spacing>
      <img
        src="/api.svg"
        alt="man holding pencil"
        width="467px"
        height="350px"
        className="max-h-40 md:max-w-1/2 md:max-h-full mb-6 md:mb-0"
      />
    </div>
  );
};
