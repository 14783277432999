import React from 'react';
import classnames from 'classnames';

import { ErrorMessage } from '../ErrorMessage';
import { Field } from '../Field';
import { Label } from '../Label';
import { Icon } from '../../Icons';
import { Text } from '../../Text';

import type { BaseInputProps, InputSize, InputVariant, SelectOption } from '../base';

import styles from '../styles.module.css';

interface SelectProps<T = any> extends BaseInputProps<T, HTMLSelectElement> {
  options: (SelectOption<T> | string)[];
  size?: InputSize;
  variant?: InputVariant;
  value?: T;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      description,
      defaultValue,
      error,
      label,
      labelVariant,
      name,
      options,
      placeholder,
      size = 'md',
      variant = 'outlined',
      id = name,
      ...props
    },
    ref,
  ) => {
    const formattedOptions = React.useMemo(() => {
      return options.map((option) =>
        typeof option === 'object'
          ? option
          : {
              label: option,
              value: option,
            },
      );
    }, [options]);

    return (
      <Field className={className}>
        {label && (
          <Label htmlFor={id} variant={labelVariant}>
            {label}
          </Label>
        )}
        {description && (
          <Text variant="caption" color="ink-500">
            {description}
          </Text>
        )}
        <div className="relative">
          <select
            {...props}
            id={id}
            name={name}
            defaultValue={defaultValue}
            ref={ref}
            className={classnames(
              className,
              styles.input,
              styles[`size-${size}`],
              styles[`padding-${size}`],
              styles[`size-${size}--icon-right`],
              styles[`variant-${variant}`],
              'w-min input-reset flex-shrink-0 cursor-pointer text-text focus:outline-none',
            )}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {formattedOptions.map((option, index) => (
              <option
                value={option.value}
                key={option.key || option.value || index}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
          </select>
          <div
            className={classnames(
              'flex absolute top-0.5 bottom-0 right-0 z-0 items-center pointer-events-none',
              styles[`padding-${size}`],
            )}
          >
            <Icon type="chevronDown" size="1rem" color="ink-700" />
          </div>
        </div>
        {error && <ErrorMessage error={error} />}
      </Field>
    );
  },
);

Select.displayName = 'Select';
