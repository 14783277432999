import React from 'react';

import { Button, Icon, Tooltip } from '@blush/mascara';
import { useCopyToClipboard } from '@blush/hooks';
import { useAnalytics } from 'hooks/useAnalytics';
import classNames from 'classnames';

interface CopyInputProps {
  value: string;
  className?: string;
  style?: React.CSSProperties;
}

export const CopyInput: React.FC<CopyInputProps> = ({
  className,
  value,
  style,
}) => {
  const analytics = useAnalytics();
  const [copyState, copy] = useCopyToClipboard();

  function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    const inputEl = event.target;

    if (inputEl) {
      inputEl.select();
    }
  }

  async function handleCopy() {
    await copy(value);
    analytics.track('Copy code', { value });
  }

  return (
    <div className={classNames('relative w-full', className)} style={style}>
      <input
        className="input-reset truncate pl-3 pr-16 py-3.5 w-full bg-ink-000 dark:bg-ink-000 border border-ink-200 dark:border-ink-200 shadow-active-none rounded text-component text-active dark:text-active-light font-medium leading-input focus:border-active focus:shadow-active transition duration-200"
        onFocus={handleFocus}
        name="copy"
        value={value}
        readOnly
      />
      <div className="absolute inset-y-0 right-2 flex items-center">
        <Tooltip content="Copy">
          <Button
            size="md"
            variant="secondary"
            className="dark:bg-ink-100"
            onClick={handleCopy}
          >
            <Icon type="duplicate" size="16px" />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
