import React from 'react';
import classnames from 'classnames';

import { Tab, TabProps } from '../Tab';

// The <TabsList> should be used when all children <Tab>s operate with the same state.
// If you need custom callbacks, or multi-selection then it's better to use the
// <Tab> component directly.
export type ChildTab = Omit<TabProps, 'selected' | 'onClick'>;

export type Tabs = ChildTab[];

export interface TabsProps<T extends ChildTab> {
  className?: string;
  label?: string;
  selected?: string;
  onClick?: (tab: T) => any;
  tabs: T[];
}

export function TabsList<T extends ChildTab>({
  className,
  label,
  selected,
  onClick,
  tabs,
}: TabsProps<T>) {
  return (
    <div
      className={classnames(
        className,
        'flex items-center justify-start overflow-x-auto scrollbars',
      )}
    >
      <div
        className="flex flex-grow space-x-4"
        role="tablist"
        aria-label={label}
      >
        {tabs.map((tab, i) => (
          <Tab
            key={tab.id || i}
            id={tab.id}
            href={tab.href}
            selected={selected === (tab.id || tab.href)}
            onClick={() => onClick?.(tab)}
          >
            {tab.children}
          </Tab>
        ))}
      </div>
    </div>
  );
}
