import React from 'react';
import classnames from 'classnames';

import { Box, BoxProps } from '../Box';

export interface ButtonIncognitoProps extends Omit<BoxProps, 'ref'> {
  disabled?: boolean;
  pressed?: boolean;
  tabIndex?: number;
  label?: string;
  title?: string;
  componentAs?: string;
  onClick?: React.MouseEventHandler;
  onDoubleClick?: React.MouseEventHandler;
  onBlur?: React.KeyboardEventHandler;
  onFocus?: React.KeyboardEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  onMouseDown?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  onMouseOver?: React.MouseEventHandler;
  onMouseUp?: React.MouseEventHandler;
}

export const ButtonIncognito: React.FC<ButtonIncognitoProps> = React.forwardRef(
  (
    {
      as,
      componentAs,
      className,
      disabled = false,
      label,
      pressed = false,
      tabIndex = 0,
      title,
      onClick,
      onDoubleClick,
      onBlur,
      onFocus,
      onKeyDown,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseOver,
      onMouseUp,
      ...props
    },
    ref,
  ) => {
    function handleKeyEvent(event: any) {
      if (typeof onKeyDown === 'function') {
        onKeyDown(event);
      }

      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();

        if (typeof onClick === 'function') {
          onClick(event);
        }
      }
    }

    const buttonProps = {
      role: 'button',
      tabIndex: disabled ? -1 : tabIndex,
      'aria-label': label || null,
      'aria-disabled': disabled || null,
      'aria-pressed': pressed || null,
      title: title || null,
      onKeyDown: disabled ? null : handleKeyEvent,
      onClick: disabled ? null : onClick,
      onDoubleClick: disabled ? null : onDoubleClick,
      onBlur: disabled ? null : onBlur,
      onFocus: disabled ? null : onFocus,
      onMouseDown: disabled ? null : onMouseDown,
      onMouseEnter: disabled ? null : onMouseEnter,
      onMouseLeave: disabled ? null : onMouseLeave,
      onMouseOver: disabled ? null : onMouseOver,
      onMouseUp: disabled ? null : onMouseUp,
    };

    const ButtonComponent = !as || typeof as === 'string' ? Box : as;

    return (
      <ButtonComponent
        as={componentAs || (typeof as === 'string' ? as : undefined)}
        ref={ref}
        {...buttonProps}
        {...props}
        className={classnames(
          className,
          'outline-none',
          disabled ? 'pointer-events-none' : 'cursor-pointer',
        )}
      />
    );
  },
);

ButtonIncognito.displayName = 'ButtonIncognito';
