export function random(start: number, end = 0) {
  const [min, max] = [start, end].sort() as [number, number];
  return min + Math.floor(Math.random() * (max - min));
}

export function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(value, max));
}

export function round(value: number, precision = 1) {
  return Math.round((value + Number.EPSILON) * 10 ** precision) / 10 ** precision;
}

export function normalize(
  value: number,
  minBefore: number,
  maxBefore: number,
  minAfter: number,
  maxAfter: number,
) {
  return ((maxAfter - minAfter) / (maxBefore - minBefore)) * (value - maxBefore) + maxAfter;
}

export function isGreaterThanZero(number: any) {
  return typeof number === 'number' && number > 0;
}

export function isPositive(number: any) {
  return typeof number === 'number' && number >= 0;
}

export function isWhole(number: any) {
  return typeof number === 'number' && number % 1 === 0;
}
