import React from 'react';

import { Spacing, Text } from '@blush/mascara';
import { CodeBlock } from 'components/IllustrationForm/CodeBlock';
import { StyleInput } from 'components/IllustrationForm/StyleInput';
import { BackgroundInput } from 'components/IllustrationForm/BackgroundInput';
import { ProportionsInput } from 'components/IllustrationForm/ProportionsInput';

export const IllustrationForm: React.FC = () => {
  return (
    <div className="flex flex-col space-y-6 md:space-y-10">
      <Spacing stack gap={2}>
        <Text variant="body-strong">Choose a style</Text>
        <StyleInput />
      </Spacing>
      <Spacing stack gap={2}>
        <Text variant="body-strong">Proportion</Text>
        <ProportionsInput />
      </Spacing>
      <Spacing stack gap={2}>
        <Text variant="body-strong">Background</Text>
        <BackgroundInput />
      </Spacing>
      <CodeBlock />
    </div>
  );
};
