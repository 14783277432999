import React from 'react';

type IgnoredProps = 'as' | 'ref';

export interface BoxProps extends Omit<React.HTMLProps<any>, IgnoredProps> {
  as?: React.ElementType;
  children?: React.ReactNode;
  [key: string]: any;
}

export const Box = React.forwardRef<HTMLElement, BoxProps>(
  ({ as: Tag = 'div', ...props }, ref) => <Tag ref={ref} {...props} />,
);

Box.displayName = 'Box';
