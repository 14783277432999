import React from 'react';
import classnames from 'classnames';
import Tippy, { TippyProps } from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import styles from './styles.module.css';

export interface TooltipProps extends Omit<TippyProps, 'animation' | 'popperOptions'> {
  boundary?: HTMLElement | null;
  flip?: boolean;
}

const defaultDelay: TippyProps['delay'] = [500, 0];
const defaultDuration: TippyProps['duration'] = [150, 150];
const defaultOffset: TippyProps['offset'] = [0, 15];
const defaultPlugins: TippyProps['plugins'] = [followCursor];
const defaultPlacement: TippyProps['placement'] = 'top';
const defaultTrigger: TippyProps['trigger'] = 'mouseenter focus';

// Instead of exposing all popperOptions, the Tooltip uses it's own props to control the
// behaviour of the tooltip (for the more common moficiations). This way the consumer doesn't
// need to know the underlying mechanics of the component.
function getPopperOptions(props: TooltipProps) {
  const options: TippyProps['popperOptions'] = {
    strategy: 'fixed',
    modifiers: [],
  };

  if (props.flip === false) {
    options.modifiers!.push({
      name: 'flip',
      enabled: false, // we always want the label to appear below the option
    });
  }

  if (props.boundary) {
    options.modifiers!.push({
      name: 'preventOverflow',
      options: {
        boundary: props.boundary,
        tether: false,
      },
    });
  }

  return options;
}

export const Tooltip = React.forwardRef<HTMLElement, TooltipProps>((props, ref) => {
  const {
    children,
    className,
    content,
    delay = defaultDelay,
    disabled,
    duration = defaultDuration,
    offset = defaultOffset,
    plugins = defaultPlugins,
    reference,
    render,
    singleton,
    visible,
    placement = defaultPlacement,
    trigger = defaultTrigger,
    ...rest
  } = props;

  const child = React.cloneElement(children as React.ReactElement, rest);

  // This is a good way to allow optional tooltips, where content may be dynamic
  if (!content) {
    return <>{child}</>;
  }

  return (
    <Tippy
      animation="shift-away"
      className={classnames(className, styles.tip)}
      zIndex={2147483647}
      placement={placement}
      content={content}
      delay={delay}
      disabled={disabled}
      duration={duration}
      offset={offset}
      plugins={plugins}
      popperOptions={getPopperOptions(props)}
      ref={ref}
      reference={reference}
      render={render}
      singleton={singleton}
      visible={visible}
      trigger={trigger}
    >
      {child}
    </Tippy>
  );
});

Tooltip.displayName = 'Tooltip';
