import React from 'react';

import { copyToClipboard } from '@blush/utils';
import { useIsMounted } from './useIsMounted';

type CopyState = 'idle' | 'pending' | 'success' | 'error';

interface CopyOptions {
  timeout?: number;
}

export function useCopyToClipboard({ timeout = 2000 }: CopyOptions = {}) {
  const [state, setState] = React.useState<CopyState>('idle');
  const isMounted = useIsMounted();
  const resetTimer = React.useRef<ReturnType<typeof setTimeout>>();

  const copy = React.useCallback(
    async (text: string) => {
      setState('pending');

      try {
        await copyToClipboard(text);

        if (isMounted()) {
          setState('success');
        }
      } catch (err) {
        if (isMounted()) {
          setState('error');
        }
      }

      resetTimer.current = setTimeout(() => {
        if (isMounted()) {
          setState('idle');
        }
      }, timeout);
    },
    [isMounted, timeout, setState],
  );

  React.useEffect(() => {
    return () => {
      if (resetTimer.current) {
        clearTimeout(resetTimer.current);
      }
    };
  }, []);

  return [state, copy] as const;
}
