import React from 'react';
import classnames from 'classnames';

import { Text, TextProps } from '../../Text';

interface LabelProps extends Omit<TextProps, 'ref'> {
  htmlFor?: string;
}

function stopPropagation(e: React.MouseEvent<HTMLLabelElement>) {
  e.stopPropagation();
}

// Use our own incase we add tooltips or something in the future
export const Label: React.FC<LabelProps> = ({
  className,
  variant = 'body',
  disabled,
  ...props
}) => (
  <Text
    as="label"
    className={classnames('mr-auto', disabled ? 'opacity-50' : '', className)}
    variant={variant}
    color="current"
    onClick={stopPropagation}
    {...props}
  />
);
