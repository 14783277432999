import React from 'react';

import { LoadingSpinner, Text } from '@blush/mascara';

import { useIllustration } from 'context/Illustration';
import { getImageUrl } from 'utils/api';
import { isDarkColor } from '@blush/utils';

export const IllustrationPreview: React.FC = React.memo(() => {
  const { params, metadata, loading } = useIllustration();
  const {
    background,
    style,
    width: requestedWidth,
    height: requestedHeight,
  } = params;

  const width = 1200;
  const aspectRatio =
    requestedWidth && requestedHeight ? requestedWidth / requestedHeight : null;
  const height = aspectRatio ? Math.round(width / aspectRatio) : undefined;
  const textColor =
    !aspectRatio && background
      ? isDarkColor(background)
        ? 'white'
        : 'black'
      : 'ink-900';

  const fullscreen = requestedWidth || requestedHeight;

  return (
    <>
      <figure className="absolute inset-0 flex justify-center items-center p-4 pb-12 lg:px-8 lg:py-12 xl:p-16 2xl:p-24">
        {metadata && (
          <img
            src={getImageUrl({
              width,
              height,
              id: metadata.id,
            })}
            style={{
              backgroundColor: fullscreen ? background : undefined,
            }}
            alt={style}
            className="rounded-lg max-w-full max-h-full bg-ink-050 dark:bg-ink-200"
          />
        )}
        {metadata && (
          <>
            <Text
              className="text-center md:text-right px-5 py-4 absolute bottom-0 right-0 left-0"
              variant="body"
              color={textColor}
            >
              Illustration by{' '}
              <a
                href={metadata.artist.url}
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                {metadata.artist.name}
              </a>
            </Text>
          </>
        )}
      </figure>

      {loading && <LoadingSpinner isCentered className="z-10" />}
    </>
  );
});

IllustrationPreview.displayName = 'IllustrationPreview';
