import React from 'react';
import classnames from 'classnames';

import { Checkbox, Spacing, TabsList } from '@blush/mascara';
import { CopyInput } from 'components/CopyInput';
import { getEmbedCode, getImageUrl } from 'utils/api';
import { useIllustration } from 'context/Illustration';

import styles from './styles.module.css';

type Mode = 'url' | 'code';

export const CodeBlock: React.FC = () => {
  const [mode, setMode] = React.useState<Mode>('url');
  const [includeAttribution, toggleIncludeAttribution] = React.useReducer(
    (prev) => !prev,
    true,
  );
  const { metadata, params } = useIllustration();

  let inputValue = '';

  if (metadata) {
    const copyParams = { ...params, id: metadata.id };
    inputValue =
      mode === 'url'
        ? getImageUrl(copyParams, {
            domain: 'https://doodleipsum.com',
          })
        : getEmbedCode(metadata, copyParams, includeAttribution);
  }

  const tabs = [
    { id: 'url' as Mode, children: 'Get URL' },
    { id: 'code' as Mode, children: 'Embed code' },
  ];

  return (
    <div
      className={classnames(
        styles.code,
        'px-3 pb-3 sm:px-6 sm:pt-3 sm:pb-6 rounded-lg border',
      )}
    >
      <Spacing stack gap={3}>
        <TabsList
          tabs={tabs}
          selected={mode}
          onClick={({ id }) => setMode(id)}
        />
        <CopyInput value={inputValue} />
        <div className="flex flex-row flex-wrap">
          {mode === 'code' && (
            <Checkbox
              name="attribution"
              labelPosition="right"
              label="Include artist attribution"
              checked={includeAttribution}
              onChange={toggleIncludeAttribution}
              className="mr-6 mb-2"
            />
          )}
        </div>
      </Spacing>
    </div>
  );
};
