import { useEffect, useState } from 'react';
import classnames from 'classnames';

import type { ThemeColor } from '../../types';

export interface LoadingSpinnerProps {
  className?: string;
  color?: ThemeColor;
  delay?: number;
  isCentered?: boolean;
  size?: number;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  className,
  color = 'active',
  delay,
  children,
  isCentered = false,
  size = 48,
}) => {
  const [isVisible, setIsVisible] = useState(!delay);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (delay) {
      timeout = setTimeout(() => {
        setIsVisible(true);
      }, delay);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  if (!isVisible) {
    return null;
  }

  // This is okay because we safelist all text-{color} classes
  const colorClassName = `text-${color}`;

  return (
    <div
      className={classnames(
        'flex flex-shrink-0 items-center justify-center',
        className,
        colorClassName,
        {
          'absolute inset-0': isCentered,
        },
      )}
    >
      <svg
        viewBox="0 0 48 48"
        stroke="currentColor"
        style={{ width: size, height: size, padding: '2px' }}
      >
        <g fill="none" fillRule="evenodd" strokeWidth={2}>
          <circle cx={24} cy={24} r={1}>
            <animate
              attributeName="r"
              begin="0s"
              dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="0s"
              dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx={24} cy={24} r={1}>
            <animate
              attributeName="r"
              begin="-0.9s"
              dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="-0.9s"
              dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
      {children}
    </div>
  );
};
