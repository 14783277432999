import React from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import { ButtonIncognito } from '../ButtonIncognito';

export interface TabProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  label?: string;
  href?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  selected?: boolean;
}

export const Tab: React.FC<TabProps> = ({ className, href, selected, ...props }) => {
  if (href) {
    const isExternalLink = href.startsWith('http');

    const link = (
      <a
        href={href}
        className={classnames(
          className,
          'flex items-center justify-center py-3 font-medium border-b-2 text-component leading-none transition-colors duration-200',
          selected
            ? 'border-current text-ink-1000'
            : 'border-transparent text-ink-500 hover:text-ink-800',
        )}
        {...props}
      />
    );

    if (isExternalLink) {
      return link;
    }

    return (
      <Link href={href} passHref>
        {link}
      </Link>
    );
  }

  return (
    <ButtonIncognito
      className={classnames(
        className,
        'flex items-center justify-center py-3 font-medium border-b-2 text-component leading-none transition-colors duration-200',
        selected
          ? 'border-current text-ink-1000'
          : 'border-transparent text-ink-500 hover:text-ink-800',
      )}
      {...props}
    />
  );
};
