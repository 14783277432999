import React from 'react';

import { IconButton, Spacing, Text, Tooltip } from '@blush/mascara';
import {
  getImageMetadata,
  getImageUrl,
  prefetchImageMetadata,
} from 'utils/api';
import { useAsyncFn } from '@blush/hooks';
import { useAnalytics } from 'hooks/useAnalytics';
import { CopyInput } from 'components/CopyInput';
import { copyToClipboard } from '@blush/utils';
import Image from 'next/image';

import type { DoodleIpsum } from '@blush/types';

interface CardProps {
  params: DoodleIpsum.ImageParams;
  copyUrl?: string;
  customCopy?: string;
  title: string;
  backgroundColor?: string;
  imgClassName?: string;
  initialImageId?: string;
}

export const Card: React.FC<CardProps> = ({
  params,
  copyUrl,
  customCopy,
  title,
  children,
  backgroundColor,
  imgClassName,
  initialImageId,
}) => {
  // NOTE: We have loading state if we want to use it...
  const [{ data, loading }, getRandom] = useAsyncFn(getImageMetadata);
  const analytics = useAnalytics();

  const imageId = data && 'id' in data ? data.id : initialImageId;
  const src = getImageUrl({
    ...params,
    id: imageId,
  });

  function handleMouseEnter() {
    prefetchImageMetadata({ ...params, id: undefined });
  }

  async function handleCopy() {
    const fullSrc = `https://doodleipsum.com${src}`;
    await copyToClipboard(fullSrc);
  }

  async function handleRandom() {
    await getRandom({ ...params, id: undefined });
    // Schedule the next request...
    prefetchImageMetadata({ ...params, id: undefined });
    analytics.track('Shuffle', { card: title });
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      className="bg-ink-000 dark:bg-ink-050 border border-transparent dark:border-ink-100 p-3 sm:p-6 rounded-2xl doodle-card min-w-0"
      style={{ flex: '0 1 50%' }}
    >
      <Spacing stack gap={4} className="h-full">
        <div className="group flex flex-grow flex-col w-full relative">
          <Image
            src={src}
            loader={({ src }) => src}
            height={300}
            width={470}
            className={`flex-shrink rounded-xl object-contain ${imgClassName}`}
            alt={title}
          />
          <Spacing
            gap={2}
            className="absolute top-2 right-2 md:opacity-0 group-hover:opacity-100"
          >
            <Tooltip content="Shuffle">
              <IconButton icon="random" onClick={handleRandom} />
            </Tooltip>
            <Tooltip content="Copy">
              <IconButton icon="duplicate" onClick={handleCopy} />
            </Tooltip>
          </Spacing>
        </div>
        <Text as="h3" variant="display-md">
          {title}
        </Text>
        <Text as="div" className="flex-grow">
          {children}
        </Text>

        {(customCopy || copyUrl) && (
          <CopyInput
            value={customCopy ?? `https://doodleipsum.com${copyUrl}`}
          />
        )}
      </Spacing>
    </div>
  );
};
