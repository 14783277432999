import React, { useEffect } from 'react';
import classnames from 'classnames';
import throttle from 'lodash/throttle';

import {
  useEventListener,
  useLayoutEffect,
  useMutationObserver,
} from '@blush/hooks';

import styles from './styles.module.css';

interface HorizontalScrollProps {
  className?: string;
}

const SHADOW_DISTANCE = 5;
const SHADOW_OPACITY = '.3';
const THROTTLE_DURATION = 60;

function updateShadows(
  parent: HTMLDivElement,
  left: HTMLDivElement,
  right: HTMLDivElement,
) {
  const { scrollLeft, scrollWidth, offsetWidth } = parent;

  const showLeft = scrollLeft > SHADOW_DISTANCE;
  const showRight = scrollLeft + offsetWidth < scrollWidth - SHADOW_DISTANCE;

  left.style.opacity = showLeft ? SHADOW_OPACITY : '0';
  right.style.opacity = showRight ? SHADOW_OPACITY : '0';
}

export const HorizontalScroll: React.FC<HorizontalScrollProps> = ({
  children,
  className,
}) => {
  const scrollerRef = React.useRef<HTMLDivElement>(null);
  const leftShadowRef = React.useRef<HTMLDivElement>(null);
  const rightShadowRef = React.useRef<HTMLDivElement>(null);

  const handleScroll = React.useMemo(() => {
    return throttle(() => {
      if (
        !scrollerRef.current ||
        !leftShadowRef.current ||
        !rightShadowRef.current
      ) {
        return;
      }

      updateShadows(
        scrollerRef.current,
        leftShadowRef.current,
        rightShadowRef.current,
      );
    }, THROTTLE_DURATION);
  }, []);

  useLayoutEffect(() => {
    // Check for shadows on mount
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Watch for content changes inside the component
  useMutationObserver(
    scrollerRef,
    {
      childList: true,
      subtree: true,
      characterData: true,
    },
    handleScroll,
  );

  useEventListener('resize', handleScroll);

  return (
    <div className={classnames('relative overflow-hidden', className)}>
      <div
        ref={scrollerRef}
        className={`flex flex-nowrap overflow-x-auto scrollbars-none`}
        onScroll={handleScroll}
      >
        <div className={styles['shadow-left']} ref={leftShadowRef} />
        {children}
        <div className={styles['shadow-right']} ref={rightShadowRef} />
      </div>
    </div>
  );
};
