import React from 'react';
import classnames from 'classnames';

import { Box, BoxProps } from '../Box';

export interface SpacingProps extends BoxProps {
  stack?: boolean;
  gap?: Gap;
}

type Gap = 'px' | 0.5 | 0 | 1 | 1.5 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10;

const gaps: Record<string, Record<Gap, string>> = {
  row: {
    px: 'space-x-px',
    0: 'space-x-0',
    0.5: 'space-x-0.5',
    1: 'space-x-1',
    1.5: 'space-x-1.5',
    2: 'space-x-2',
    3: 'space-x-3',
    4: 'space-x-4',
    5: 'space-x-5',
    6: 'space-x-6',
    8: 'space-x-8',
    9: 'space-x-9',
    10: 'space-x-10',
  },
  col: {
    px: 'space-y-px',
    0: 'space-y-0',
    0.5: 'space-y-0.5',
    1: 'space-y-1',
    1.5: 'space-y-1.5',
    2: 'space-y-2',
    3: 'space-y-3',
    4: 'space-y-4',
    5: 'space-y-5',
    6: 'space-y-6',
    8: 'space-y-8',
    9: 'space-y-9',
    10: 'space-y-10',
  },
};

export const Spacing = React.forwardRef<HTMLElement, SpacingProps>(
  ({ className, stack, gap = 0, ...props }, ref) => {
    const direction = stack ? 'flex-col' : 'flex-row';
    const space = gaps[stack ? 'col' : 'row']![gap];

    return (
      <Box
        {...props}
        className={classnames('flex', className, direction, space)}
        ref={ref}
      />
    );
  },
);

Spacing.displayName = 'Spacing';
