import React, { useCallback } from 'react';
import classnames from 'classnames';

import {
  Button,
  ButtonIncognito,
  DarkModeToggle,
  Spacing,
  Text,
} from '@blush/mascara';
import { IllustrationForm } from 'components/IllustrationForm';
import { IllustrationPreview } from 'components/IllustrationPreview';
import {
  IllustrationContextProvider,
  useIllustration,
} from 'context/Illustration';
import { useAnalytics } from 'hooks/useAnalytics';

import styles from './styles.module.css';

const HeroImpl: React.FC = () => {
  const { params, random } = useIllustration();
  const analytics = useAnalytics();
  const { width, height, background } = params;
  const hasAspectRatio = !!(width && height);
  const backgroundColor =
    !hasAspectRatio && background ? background : undefined;

  function handleRandom() {
    random();
    analytics.track('Shuffle', { card: 'Hero' });
  }

  return (
    <div className="flex flex-col-reverse md:flex-row flex-grow items-center justify-center md:min-h-screen dark:bg-ink-050 pb-8 md:pb-0">
      <div className={styles.form}>
        <div
          className={classnames(
            styles.content,
            'flex flex-grow justify-center flex-col space-y-3 md:space-y-10',
          )}
        >
          <a
            href="https://www.producthunt.com/posts/doodle-ipsum?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-doodle-ipsum"
            target="_blank"
            className="hidden md:block w-60"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=312633&theme=light"
              alt="Doodle Ipsum - The lorem ipsum of illustrations | Product Hunt"
              style={{
                width: '250px',
                height: '54px',
              }}
              width="250"
              height="54"
            />
          </a>
          <Spacing stack gap={2}>
            <Text as="h2" variant="display-sm" className="hidden md:block">
              Blush Presents
            </Text>
            <Text as="h1" variant="display-xl" className="xl:hero">
              Doodle Ipsum
            </Text>
            <Text as="p" variant="body-lg" className="hidden md:block w-4/5">
              The lorem ipsum of illustrations. Just customize your doodles,
              grab the code, and use them on your web prototypes, landing pages,
              or no-code tools.
            </Text>
            <div
              className={classnames(styles.illustration, 'flex md:hidden')}
              style={{ backgroundColor }}
            >
              <IllustrationPreview />
            </div>
            <Button
              iconLeft="random"
              className="flex md:hidden m-auto"
              variant="secondary"
              onClick={handleRandom}
            >
              Shuffle
            </Button>
          </Spacing>
          <IllustrationForm />
        </div>
      </div>
      <ButtonIncognito
        className={classnames(
          styles.illustration,
          'group cursor-pointer hidden md:flex',
          {
            'bg-ink-050 dark:bg-ink-200': !hasAspectRatio,
          },
        )}
        style={{ backgroundColor }}
        onClick={handleRandom}
      >
        <DarkModeToggle className="absolute top-4 right-4 z-10" />
        <IllustrationPreview />
        <Button
          iconLeft="random"
          className="opacity-0 z-10 group-hover:opacity-100 transition-opacity pointer-events-none duration-300"
          variant="secondary"
        >
          Shuffle
        </Button>
      </ButtonIncognito>
    </div>
  );
};

export const Hero: React.FC = () => (
  <IllustrationContextProvider>
    <HeroImpl />
  </IllustrationContextProvider>
);
