import React from 'react';
import classnames from 'classnames';

import { Field } from '../Field';
import { Label } from '../Label';

import type { BaseInputProps } from '../base';

import styles from './styles.module.css';

interface CheckboxProps extends BaseInputProps<boolean, HTMLInputElement> {
  defaultChecked?: boolean;
  checked?: boolean;
  labelPosition?: 'left' | 'right';
  ref?: React.Ref<HTMLInputElement>;
  value?: string;
}

export const Checkbox: React.FC<CheckboxProps> = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      className,
      defaultChecked,
      disabled,
      error,
      label,
      labelPosition = 'left',
      labelVariant,
      name,
      onBlur,
      onChange,
      onFocus,
      value,
      id = name,
    },
    ref,
  ) => {
    const inputRef = React.useRef<HTMLInputElement>();

    const inputRefCallback = React.useCallback((element: HTMLInputElement) => {
      if (typeof ref === 'function') {
        ref(element);
      }

      if (ref && 'current' in ref) {
        ref.current = element;
      }

      inputRef.current = element;
    }, []);

    React.useEffect(() => {
      if (!inputRef.current || typeof defaultChecked === 'undefined') {
        return;
      }

      inputRef.current.checked = defaultChecked;
    }, [defaultChecked]);

    return (
      <Field className={className}>
        <div
          className={classnames('flex space-x-3 items-center', {
            'space-x-reverse flex-row-reverse': labelPosition === 'right',
          })}
        >
          {label && (
            <Label htmlFor={id} variant={labelVariant}>
              {label}
            </Label>
          )}
          <label
            className={classnames(
              'flex',
              disabled ? 'cursor-default opacity-60 pointer-events-none' : 'cursor-pointer',
            )}
          >
            <input
              type="checkbox"
              checked={checked}
              defaultChecked={defaultChecked}
              disabled={disabled}
              name={name}
              id={id}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChange}
              value={value}
              ref={inputRefCallback}
              className={classnames(styles.input, 'input-reset opacity-0 w-0 h-0')}
            />
            <span className={styles.box}>
              <span className={styles.tick} />
            </span>
          </label>
        </div>
      </Field>
    );
  },
);

Checkbox.displayName = 'Checkbox';
