import React from 'react';

import { useCallbackRef } from './useCallbackRef';

export const useMutationObserver = (
  ref: React.MutableRefObject<HTMLElement | null>,
  config: MutationObserverInit,
  callback: (mutationsList: MutationRecord[]) => void,
): void => {
  const callbackRef = useCallbackRef(callback);

  React.useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const mutationHandler = (mutationsList: MutationRecord[]): void => {
      if (mutationsList.length > 0) {
        callbackRef(mutationsList);
      }
    };

    const observer = new MutationObserver(mutationHandler);
    observer.observe(element, config);

    return (): void => {
      observer.disconnect();
    };
    // We inentionally don't update the mutation observer, even if the config changes.
    // If we need a dynamic config then we can look into a more robust solution, but for
    // now this works fine and is the most performant.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
