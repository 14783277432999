import React from 'react';
import classnames from 'classnames';

import {
  ButtonIncognito,
  HorizontalScroll,
  Icon,
  Spacing,
  Text,
} from '@blush/mascara';
import { useIllustration } from 'context/Illustration';
import { ART_STYLES } from '@blush/constants';
import { getImageUrl, prefetchImageMetadata } from 'utils/api';
import { useAnalytics } from 'hooks/useAnalytics';

export const StyleInput: React.FC = () => {
  const { update, params } = useIllustration();
  const analytics = useAnalytics();
  const { style } = params;

  function handleClick(style: string) {
    update({ style, id: undefined });
    analytics.track('Change style', { style });
  }

  return (
    <HorizontalScroll>
      <Spacing gap={2}>
        {ART_STYLES.map((artStyle) => (
          <Spacing key={artStyle.value} stack gap={1}>
            <ButtonIncognito
              as="button"
              type="button"
              onMouseEnter={() =>
                prefetchImageMetadata({ style: artStyle.value, id: undefined })
              }
              onClick={() => handleClick(artStyle.value)}
              className={classnames(
                'button-reset group relative w-24 h-16 rounded-lg bg-white border transition duration-200 overflow-hidden',
                style === artStyle.value
                  ? 'border-active shadow-active'
                  : 'border-ink-200 shadow-active-none',
              )}
            >
              <img
                src={getImageUrl({
                  style: artStyle.value,
                  width: 160,
                  height: 160,
                })}
                className="w-full h-full object-contain"
                alt={artStyle.label}
              />
              <div className="flex items-center justify-center absolute inset-px rounded-lg opacity-0 group-hover:opacity-70 transition-opacity duration-200 bg-ink-000 text-ink-700">
                <Icon type="random" size="1.5rem" />
              </div>
            </ButtonIncognito>
            <Text>{artStyle.label}</Text>
          </Spacing>
        ))}
      </Spacing>
    </HorizontalScroll>
  );
};
