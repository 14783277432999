import React, { useRef } from 'react';

import { Button, Input, Spacing, Icon } from '@blush/mascara';
import { RANDOM_COLORS } from 'common/constants';
import { useIllustration } from 'context/Illustration';
import { colorToHex, isValidColor, random } from '@blush/utils';
import { useAnalytics } from 'hooks/useAnalytics';

export const BackgroundInput: React.FC = () => {
  const { metadata, update, params } = useIllustration();
  const analytics = useAnalytics();
  const colorInput = useRef<HTMLInputElement>(null);
  const { background } = params;

  function setBackground(newBackground: string) {
    update({
      background: newBackground,
      id: metadata?.id,
    });

    analytics.track('Change background', { color: newBackground });
  }

  function handleValidateColor() {
    const color = colorInput.current?.value;

    if (color && (isValidColor(color) || color === '')) {
      const hex = color ? colorToHex(color) : '';
      colorInput.current.value = hex;
      setBackground(hex);
    }
  }

  return (
    <Spacing gap={2}>
      <Button
        iconLeft="transparent"
        variant={background ? 'secondary' : 'active'}
        onClick={() => {
          setBackground('');
        }}
      >
        <span>Transparent</span>
      </Button>
      <Button
        variant="secondary"
        onClick={() => {
          const index = random(0, RANDOM_COLORS.length - 1);
          setBackground(RANDOM_COLORS[index]!);
        }}
      >
        <Icon type="random" size="1rem" />
        <span className="hidden ml-2 sm:inline">Random</span>
      </Button>
      <Input
        ref={colorInput}
        size="md"
        name="bg"
        selectOnFocus
        defaultValue={background}
        placeholder="# Hex code"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleValidateColor();
          }
        }}
        onBlur={() => {
          handleValidateColor();
        }}
      />
    </Spacing>
  );
};
