import React from 'react';

import { Container } from 'components/Container';
import { Icon, Spacing, Text } from '@blush/mascara';
import {
  BLUSH_LICENSE_URL,
  BLUSH_TERMS_URL,
  BLUSH_PRIVACY_URL,
  BLUSH_INSTAGRAM_URL,
  BLUSH_TWITTER_URL,
} from 'common/constants';

export const Footer: React.FC = () => {
  return (
    <div className="flex py-14 bg-ink-000">
      <Container className="flex-col space-y-6 md:space-y-0 md:flex-row md:justify-between items-center md:items-end">
        <Spacing stack gap={1}>
          <Text as="h3" variant="display-lg">
            Doodle Ipsum
          </Text>
          <Text as="strong" variant="body-strong" className="hover:underline">
            <a href="https://blush.design">Powered by Blush</a>
          </Text>
        </Spacing>
        <div className="flex flex-col space-y-6 md:space-y-2">
          <div className="flex flex-col items-center space-y-2 md:space-y-0 md:flex-row md:space-x-8 md:justify-end">
            <Text as="a" href={BLUSH_LICENSE_URL} variant="component-strong">
              Blush License Agreement
            </Text>
            <Text variant="component-strong">English</Text>
            <Spacing gap={2}>
              <a href={BLUSH_TWITTER_URL} target="_blank" rel="noopener">
                <Icon type="twitter" size="24px" />
              </a>
              <a href={BLUSH_INSTAGRAM_URL} target="_blank" rel="noopener">
                <Icon type="instagram" size="24px" />
              </a>
            </Spacing>
          </div>
          <div className="flex flex-col items-center md:items-end space-y-2 md:flex-row md:space-x-8 md:justify-end">
            <Text variant="caption">
              &copy; Blush Design Inc. All Rights Reserved
            </Text>
            <Spacing gap={8}>
              <Text
                as="a"
                href={BLUSH_TERMS_URL}
                variant="caption"
                target="_blank"
                rel="noopener"
              >
                Terms of Service
              </Text>
              <Text
                as="a"
                href={BLUSH_PRIVACY_URL}
                variant="caption"
                target="_blank"
                rel="noopener"
              >
                Privacy Policy
              </Text>
            </Spacing>
          </div>
        </div>
      </Container>
    </div>
  );
};
