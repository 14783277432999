import React from 'react';

import classnames from 'classnames';

import { Toggle } from '../Forms';
import { useDarkMode } from '../../hooks';

interface DarkModeToggleProps {
  className?: string;
}

export const DarkModeToggle: React.FC<DarkModeToggleProps> = ({
  className,
}) => {
  const darkMode = useDarkMode();

  return (
    <div
      className={classnames(
        className,
        'flex-shrink-0 whitespace-nowrap border border-ink-200 bg-ink-000 dark:bg-ink-050 rounded-md p-3',
      )}
    >
      <Toggle
        name="dark-mode"
        labelPosition="right"
        label="Dark mode"
        checked={darkMode.value}
        onChange={darkMode.toggle}
      />
    </div>
  );
};
