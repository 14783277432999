import ImgixClient from '@imgix/js-core';
import * as Sentry from '@sentry/nextjs';

import { isDarkColor } from '@blush/utils';

import type { API, DoodleIpsum } from '@blush/types';

const imgixClient = new ImgixClient({
  domain: 'images.blush.design',
  includeLibraryParam: false,
});

type Options = {
  domain?: string;
};

const prefetchCache: Record<
  string,
  Promise<API.DoodleIpsum.ImageMetaData.Response>
> = {};

export function prefetchImageMetadata(
  params: DoodleIpsum.ImageParams,
  options: Options = {},
) {
  const url = getMetadataImageUrl(params, options);

  if (prefetchCache[url]) {
    return;
  }

  prefetchCache[url] = fetchImageMetadata(url, true);
}

export async function getImageMetadata(
  params: DoodleIpsum.ImageParams,
  options: Options = {},
) {
  const url = getMetadataImageUrl(params, options);
  const result = (await prefetchCache[url]) || fetchImageMetadata(url);
  delete prefetchCache[url];

  return result;
}

async function fetchImageMetadata(url: string, preload = false) {
  const response = await fetch(url);

  if (response.status !== 200) {
    // Error handling
  }

  const result: API.DoodleIpsum.ImageMetaData.Response = await response.json();

  if ('url' in result && preload) {
    preloadImage(result.url);
  }

  return result;
}

function getMetadataImageUrl(
  params: DoodleIpsum.ImageParams,
  options: Options = {},
) {
  const fetchParams = {
    style: params.style,
    id: params.id,
    width: 1, // For metadata, always use the same [width]x[height] url for caching
    height: 1,
    format: 'json' as 'json',
  };

  if (fetchParams.id) {
    // Don't include style if there's an id (better for cache)
    delete fetchParams.style;
  }

  return getImageUrl(fetchParams, options);
}

function preloadImage(url: string) {
  const img = new Image();
  img.src = url;
}

export function getImageUrl(
  params: DoodleIpsum.ImageParams,
  options?: Options,
) {
  const {
    width,
    height,
    style = '',
    query = {},
    background,
    id,
    format,
  } = params;

  const parts = [options?.domain ?? ''];

  parts.push(`${width ? width : ''}${height ? `x${height}` : ''}`);

  if (style) {
    parts.push(style);
  }

  const imgixQuery = Object.entries(query).reduce((prev, [key, value]) => {
    if (typeof value !== 'undefined') {
      prev[key] = value;
    }

    return prev;
  }, {} as DoodleIpsum.ImgixQuery);

  if (background) {
    imgixQuery.bg = background.replace('#', '');
  }

  if (id) {
    imgixQuery.i = id;
  }

  if (format) {
    imgixQuery.format = format;
  }

  return `${parts.join('/')}${imgixClient._buildParams(imgixQuery)}`;
}

export function getEmbedCode(
  metadata: DoodleIpsum.Metadata,
  params: DoodleIpsum.ImageParams,
  includeAttribution: boolean,
) {
  const src = getImageUrl(
    { ...params, id: metadata.id },
    {
      domain: 'https://doodleipsum.com',
    },
  );

  const alt = `${metadata.name} by ${metadata.artist.name}`;
  const imageElement = `<img src="${src}" alt="${alt}" />`;
  const textColor =
    params.background && isDarkColor(params.background) ? 'white' : 'black';

  return includeAttribution
    ? `<div style="display:inline-block;position:relative">${imageElement}<p style="position:absolute;bottom:.5rem;right:.5rem;font-family:sans-serif;color:${textColor}">Illustration by <a href="${metadata.artist.url}">${metadata.artist.name}</a></p></div>`
    : imageElement;
}

export function apiMiddleware(
  apiHandler: (req: API.Request<any, any>, res: API.Response<any>) => void,
) {
  return Sentry.withSentry(async (req: any, res: any) => {
    return apiHandler(req, res);
  });
}
