export const ART_STYLES = [
  { label: 'Random', value: '' },
  { label: 'Flat', value: 'flat' },
  { label: 'Hand drawn', value: 'hand-drawn' },
  { label: 'Outline', value: 'outline' },
  { label: 'Abstract', value: 'abstract' },
  { label: 'Avatar', value: 'avatar' },
  { label: 'Avatar 2', value: 'avatar-2' },
  { label: 'Avatar 3', value: 'avatar-3' },
  { label: 'Avatar 4', value: 'avatar-4' },
  { label: 'Avatar 5', value: 'avatar-5' },
];
