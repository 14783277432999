/**
 * Environment
 * NOTE: Use globalThis.window, not window, to fix issues with web workers and webpack
 */
export const IS_SERVER = typeof globalThis?.window === 'undefined';
export const IS_EMBEDDED = typeof globalThis?.window !== 'undefined' && window.top !== window;

/**
 * Blush
 */
export const BLUSH_WEBSITE_URL = 'https://blush.design';

/**
 * Doodle Ipsum
 */
export * from './doodleIpsum';
