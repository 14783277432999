import React from 'react';
import classnames from 'classnames';

import type { ThemeColor } from '../../types';

// Text variants are just classes generated by a tailwind plugin. If you need responsive
// variants than pass the larger breakpoint variant as a class name, e.g. className="md:hero"
export type TextVariant =
  | 'hero'
  | 'display-xl'
  | 'display-lg'
  | 'display-md'
  | 'display-sm'
  | 'display-xl:sm'
  | 'display-lg:sm'
  | 'display-md:sm'
  | 'display-sm:sm'
  | 'display-xl:lg'
  | 'display-lg:lg'
  | 'display-md:lg'
  | 'display-sm:lg'
  | 'heading'
  | 'heading-sm'
  | 'body'
  | 'body-strong'
  | 'body-lg'
  | 'body-lg-strong'
  | 'caption'
  | 'component'
  | 'component-strong'
  | 'component-sm'
  | 'component-sm-strong';

export interface TextProps extends Omit<React.HTMLProps<any>, 'as'> {
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  variant?: TextVariant;
  color?: ThemeColor;
}

export const Text = React.forwardRef<HTMLElement, TextProps>(
  (
    {
      as: Tag = 'p',
      variant = 'body',
      color = 'ink-1000',
      className,
      ...props
    },
    ref,
  ) => (
    <Tag
      ref={ref}
      className={classnames(className, variant, {
        [`text-${color}`]: color,
      })}
      {...props}
    />
  ),
);

Text.displayName = 'Text';
