import React from 'react';

import { useAsyncFn } from '@blush/hooks';
import { getImageMetadata, prefetchImageMetadata } from 'utils/api';

import type { DoodleIpsum } from '@blush/types';

interface IllustrationContext {
  params: DoodleIpsum.ImageParams;
  metadata: DoodleIpsum.Metadata | undefined;
  loading: boolean;
  update(params: Partial<DoodleIpsum.ImageParams>): void;
  random(): void;
}

const defaultParams: DoodleIpsum.ImageParams = {
  width: 700,
  style: 'flat',
};

const IllustrationContext = React.createContext<IllustrationContext>({
  params: defaultParams,
  metadata: undefined,
  loading: false,
  update() {},
  random() {},
});

export const useIllustration = () => React.useContext(IllustrationContext);

export const IllustrationContextProvider: React.FC = ({ children }) => {
  const [{ loading, data }, fetchMetadata] = useAsyncFn(
    async (params: DoodleIpsum.ImageParams) => {
      const result = await getImageMetadata(params);
      prefetchImageMetadata(params);

      return result as DoodleIpsum.Metadata;
    },
  );
  const [params, setParams] = React.useState(defaultParams);

  React.useEffect(() => {
    fetchMetadata(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = React.useMemo(() => {
    function update(inputParams: Partial<DoodleIpsum.ImageParams>) {
      const newParams = { ...params, ...inputParams };

      setParams(newParams);
      fetchMetadata(newParams);
    }

    function random() {
      fetchMetadata(params);
    }

    return {
      params,
      metadata: data,
      loading,
      update,
      random,
    };
  }, [loading, data, params, fetchMetadata]);

  return (
    <IllustrationContext.Provider value={contextValue}>
      {children}
    </IllustrationContext.Provider>
  );
};
